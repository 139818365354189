import Img from "gatsby-image"
import React from "react"
import { omitNull } from "./../../hooks/useOmitNull"
import { Video } from "./../Global/Video"

export const FeaturedMedia = ({ featuredMedia }) => {
  const result = {
    ...omitNull(featuredMedia[0]),
    ...omitNull(featuredMedia[1]),
  }

  if (result.video) {
    return (
      <>
        <Video
          url={result.video}
          controls={false}
          playing={true}
          muted={true}
          loop={true}
          playsinline={true}
          style={{ background: "#efecea" }}
        />
      </>
    )
  } else {
    return (
      <>
        <Img sizes={result.image.childImageSharp.sizes} />
      </>
    )
  }
}
