import { options } from "components/Global/SRLOptions"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import { SRLWrapper } from "simple-react-lightbox"
import { Container } from "./../components/Global/Container"
import { ProjectFooter } from "./../components/Project/ProjectFooter"
import { ProjectHeader } from "./../components/Project/ProjectHeader"
import { Layout } from "./Layout"

const ProjectLayout = props => {
  const { data, pageContext } = props
  const { frontmatter, body } = data.mdx

  return (
    <Layout {...props} frontmatter={frontmatter}>
      <ProjectHeader frontmatter={frontmatter} />
      <article>
        <Container>
          <SRLWrapper options={options}>
            <MDXRenderer frontmatter={frontmatter}>{body}</MDXRenderer>
          </SRLWrapper>
        </Container>
      </article>
      <ProjectFooter context={pageContext} />
    </Layout>
  )
}

export default ProjectLayout

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        kicker
        date(formatString: "YYYY MMMM Do")
        tags
        client
        timeline
        featuredMedia {
          video
          image {
            publicURL
            childImageSharp {
              sizes(maxWidth: 2000, traceSVG: { color: "#95aaa8" }) {
                ...GatsbyImageSharpSizes_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
