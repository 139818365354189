export const options = {
  // https://github.com/michelecocuccio/simple-react-lightbox#options
  settings: {
    autoplaySpeed: 0,
    // disableKeyboardControls: false,
    disablePanzoom: true,
    // disableWheelControls: false,
    // hideControlsAfter: 3000,
    lightboxTransitionSpeed: 0.3,
    // lightboxTransitionTimingFunction: 'linear',
    overlayColor: "rgba(40,40,41,.9)",
    // slideAnimationType: "fade",
    // slideSpringValues: [300, 200],
    slideTransitionSpeed: 0.2,
    // slideTransitionTimingFunction: "linear",
  },
  buttons: {
    backgroundColor: "rgba(30,30,36,0.8)",
    iconColor: "rgba(239, 236, 234, .8)",
    iconPadding: ".5em",
    showAutoplayButton: false,
    showDownloadButton: false,
    showThumbnailsButton: false,
    size: "2.125rem",
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
}
