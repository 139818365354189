import React from "react"
import styled from "styled-components"
import { Container } from "../Global/Container"
import Link from "../Global/Link"

const Arrow = styled.span`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.black50};
  display: inline-block;
  margin-top: 1px;
`

const Divider = styled.span`
  color: ${props => props.theme.colors.black50};
  font-size: 1.25rem;
`

const StyledLink = styled(Link)`
  margin: 0 1rem;
  text-decoration: none !important;
  font-size: 1.25rem;

  &:hover {
    color: ${props => props.theme.colors.blue} !important;
    text-decoration: underline !important;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  border-top: 0.5px solid ${props => props.theme.colors.black};
  margin-top: 3rem;
`

export const ProjectFooter = props => {
  const { previous, next } = props.context
  const divider = previous && next ? true : false
  return (
    <footer {...props}>
      <Container>
        <Flex>
          {previous === false ? null : (
            <>
              {previous && (
                <>
                  <Arrow>← </Arrow>
                  <StyledLink to={previous.fields.slug}>
                    <p>{previous.frontmatter.title}</p>
                  </StyledLink>
                </>
              )}
            </>
          )}
          <Divider>{divider && "/"}</Divider>
          {next === false ? null : (
            <>
              {next && (
                <>
                  <StyledLink to={next.fields.slug}>
                    <p>{next.frontmatter.title}</p>
                  </StyledLink>
                  <Arrow> →</Arrow>
                </>
              )}
            </>
          )}
        </Flex>
      </Container>
    </footer>
  )
}
