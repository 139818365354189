import React from "react"
import styled from "styled-components"
import { Container } from "../Global/Container"
import { FullWidth } from "../MDXElements/FullWidth"
import { H1 } from "../MDXElements/H1"
import { H2 } from "../MDXElements/H2"
import { FeaturedMedia } from "./FeaturedMedia"
import { ProjectMeta } from "./ProjectMeta"

const ProjectTag = styled.li`
  font-family: "Panama";
  font-weight: 400;
  font-style: italic;
  &:not(:last-child) {
    margin-right: 0.25em;
  }
`

const ProjectTags = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 4rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: -2rem;
    transform: translateX(-50%);
    width: 1rem;
    border-top: 0.5px solid ${props => props.theme.colors.black};
  }
`

const Title = styled.div`
  text-align: center;
`

const Kicker = styled.div`
  margin-top: 2rem;
  max-width: 55ch;
  text-align: center;
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
`

const StyledHeader = styled.header``

export const ProjectHeader = props => {
  const { title, kicker, tags, featuredMedia } = props.frontmatter

  return (
    <StyledHeader {...props}>
      <Container>
        <Flex>
          <Title>
            <H1>{title}</H1>
          </Title>
          <Kicker>
            <H2 className="balance-text">{kicker}</H2>
          </Kicker>

          <ProjectTags>
            {tags.map((tag, index) => {
              return (
                <ProjectTag key={index}>
                  <span>
                    {tag}
                    {index < tags.length - 1 ? ", " : null}
                  </span>
                </ProjectTag>
              )
            })}
          </ProjectTags>

          <ProjectMeta frontmatter={props.frontmatter} />
        </Flex>

        <FullWidth>
          <FeaturedMedia featuredMedia={featuredMedia} />
        </FullWidth>
      </Container>
    </StyledHeader>
  )
}
